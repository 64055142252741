import React, { useRef, useState, useEffect, useMemo } from "react";
import Overlay, { OverlayProps } from "react-bootstrap/Overlay";

import mapboxgl, { MapboxOptions } from "mapbox-gl/dist/mapbox-gl-csp";
import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";

import MapMenu from "./MapMenu";
// import MapStyleButton from "./MapStyleButton";
import LoadingSpinner from "../misc/LoadingSpinner";
import MapManager from "./../../map/MapManager";

import { SetState, MapDoc, MapMenuConfig, Mapbox, PopupRef } from "../../misc/Types";
import throttle from "lodash/throttle";

import * as Sentry from "@sentry/react";

import { getPerformance, trace } from "firebase/performance";
import { buildSMAVisibilityCallback } from "../../layers";
import TemporalControl from "./TemporalControl";
import MapStateProvider from "../../provider/MapStateProvider";
import StateProvider from "../../provider/StateProvider";
// import { LatLngControl } from "./LatLngControl";

const performance = getPerformance();

export type SetLegendSections = SetState<MapMenuConfig>;

export type MapOptions = React.PropsWithoutRef<{
  mapDocument?: MapDoc;
  mapManager: MapManager;
}>;

if (!process.env.REACT_APP_MAPBOX_ACCESS_TOKEN) {
  throw new Error("No mapboxgl access token provided");
}

//mapboxgl.workerUrl = "https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl-csp-worker.js";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const defaultMapboxOptions = {
  // w/o NOAA SMA data
  //style: 'mapbox://styles/entiat/cket13rpi5uyx19mxa22ywfhp',
  // w/ NOAA SMA data
  style: "mapbox://styles/entiat/ckm41nsne6h5017qsvjpsdllv",
  center: [-75, 40],
  zoom: 3,
  minZoom: 3,
  clickTolerance: 5,
  antialias: true,
  crossSourceCollisions: false,
  customAttribution: [
    `© ${new Date(Date.now()).getFullYear()} Mysticetus LLC`
  ],
};


export type OverlayTarget = OverlayProps["target"];

const handleSizing = (element: HTMLElement) => {
  if (element && element.offsetWidth % 2 !== 0) {
    element.style.width = `${element.offsetWidth + 1}px`;
  }

  if (element && element.offsetHeight % 2 !== 0) {
    element.style.height = `${element.offsetHeight + 1}px`;
  }
};

const handlePopupSizing = (popup: mapboxgl.Popup) => {
  const container = popup?.target?._container;

  if (container) {
    handleSizing(container);
  }
};

type MapResizeEvent =  mapboxgl.MapboxEvent<undefined> & mapboxgl.EventData;

const handleMapResizing = (event: MapResizeEvent) => {
  const canvas = event?.target?.getCanvas();

  if (canvas) {
    handleSizing(canvas);
  }
};


const Map = (props: MapOptions): JSX.Element => {
  if (document.body.style.overflow !== "hidden") {
    document.body.style.overflow = "hidden";
  }

  const { mapDocument, mapManager } = props;

  const [map, setMap] = useState<null | Mapbox.Map>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [legendSections, setLegendSections] = useState<null | MapMenuConfig>(null);

  const mapContainer = useRef<HTMLDivElement>(null);
  const pageContainer = useRef<HTMLDivElement>(null);

  const popupRef: PopupRef = useRef(new mapboxgl.Popup({
    offset: 5,
    maxWidth: "30em",
    closeButton: false,
    closeOnClick: false, // we manually override this in MapSetup to get the desired behavior
  }));

  // If the popup windows aren't an even number of pixels wide, they become
  // blurry. Every time one pops up, make sure it has an even width;
  popupRef.current?.on("open", handlePopupSizing);

  const escPressHandler = (e: KeyboardEvent) => {
    if (popupRef.current && e.key && e.key === "Escape") {
      try {
        popupRef.current.remove();
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    }
  };

  const containerClass = mapDocument
    ? "full-page-map-container"
    : "preview-map-container";

  useEffect(() => {
    if (mapContainer.current) {
      const mapboxOpts: MapboxOptions = {
        ...defaultMapboxOptions,
        container: mapContainer.current,
      };

      setMap(new mapboxgl.Map(mapboxOpts));
    }

    if (mapManager) {
      mapManager.setMapLegendChangeHandler(setLegendSections);
      mapManager.loadMenuConfig().then(mapMenuConfig => {
        setLegendSections(mapMenuConfig);
      });
    }

    document.addEventListener("keydown", escPressHandler);

    return () => {
      setIsLoading(true);

      mapManager?.cleanUp();

      map?.remove();
    };
  }, [mapDocument]);

  useEffect(() => {
    try {
      if (!map || !mapDocument || mapManager.mapInitialized) return;

      const load_trace = trace(performance, "MAP_LOAD");

      load_trace.start();
      map.on("resize", throttle(handleMapResizing, 100, { leading: true }));
      map.on("move", throttle(() => handlePopupSizing(popupRef.current), 100, { leading: true }));
      map.on("moveend", () => setTimeout(() => handlePopupSizing(popupRef.current), 100));

      map.on("error", (err) => console.error("Map Error Event: ", err));

      const scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: "metric",
      });
      
      const zoomControl = new mapboxgl.NavigationControl({
        visualizePitch: true,
      });

      const fullscreenControl = new mapboxgl.FullscreenControl({
        container: pageContainer.current,
      });

      //const mapClientInfo = new MapClientInfo({mapDocument: mapDocument});

      // const mapStyleButton = new MapStyleButton({ map: map });

      // local debug helper
      // map.addControl(new LatLngControl(), "bottom-left");
      

      map.addControl(scaleControl, "bottom-right");
      //map.addControl(mapClientInfo, "bottom-right");

      map.addControl(fullscreenControl, "top-left");
      map.addControl(zoomControl, "top-left");

      map.on("load", () => {
        try {
          // needs to happen right away, but also wont work until the map loads.
          // run every 600 seconds (10 minutes)
          setInterval(buildSMAVisibilityCallback(map), 600 * 1000);
          // map.addControl(mapStyleButton, "bottom-left");

          if (mapManager) {
            mapManager.setupMap(map, popupRef).then(() => {
              load_trace.stop();
              setIsLoading(false);
            });
          }
        } catch (err) {
          Sentry.captureException(err);
        }
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [map]);

  useEffect(() => {
    try {
      if (map && mapManager && legendSections) {
        // map.addControl(new TemporalControl(), "bottom-left");

        const mapMenu = new MapMenu({ legendSections, mapManager });

        map.addControl(mapMenu, "top-right");
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [legendSections]);

  return (
    <StateProvider>
      <MapStateProvider>
        <div ref={pageContainer}>
          {
            isLoading
              ? <LoadingSpinner>
                {
                  mapDocument && mapDocument.displayName
                    ? `Loading ${mapDocument.displayName} Map...`
                    : "Loading Map..."
                }
              </LoadingSpinner>
              : null
          }
          <div className={containerClass} ref={mapContainer}/>
          <Overlay target={popupRef.current as OverlayTarget}>
            <div/>
          </Overlay>
        </div>
      </MapStateProvider>
    </StateProvider>
  );
};

export default Map;
